import React, { Fragment } from "react";
import { Formik } from "formik";

const Form = () => {
	return (
		<Fragment>
			<Formik
				initialValues={{
					nombre: "",
					email: "",
					tel: "",
					evento: "",
					fecha: "",
					numero: "",
					mensaje: "",
				}}
				validate={(values) => {
					const errors = {};
					if (!values.email) {
						errors.email = "Este campo es requerido";
					} else if (
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
							values.email
						)
					) {
						errors.email = "Email no valido";
					}
					if (!values.tel) {
						errors.tel = "Este campo es requerido";
					}
					if (!values.nombre) {
						errors.nombre = "Este campo es requerido";
					}
					if (!values.fecha) {
						errors.fecha = "Selecciona la fecha de tu evento";
					}
					if (
						values.evento === "0" ||
						values.evento === "" ||
						values.evento === null ||
						values.evento === 0
					) {
						errors.evento = "Selecciona tipo de evento";
					}
					if (
						values.numero === "0" ||
						values.numero === "" ||
						values.numero === null ||
						values.numero === 0
					) {
						errors.numero = "Selecciona un número aproximado";
					}
					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					/* setTimeout(() => {
						alert(JSON.stringify(values, null, 2));
						setSubmitting(false);
					}, 400); */

					const valores = Object.entries(values);

					const direccion =
						"http://barcelonaeventos.com.mx/formulario/process.php?" +
						"nombre=" +
						encodeURI(valores[0][1]) +
						"&email=" +
						encodeURI(valores[1][1]) +
						"&tel=" +
						encodeURI(valores[2][1]) +
						"&evento=" +
						encodeURI(valores[3][1]) +
						"&fecha=" +
						encodeURI(valores[4][1]) +
						"&numero=" +
						encodeURI(valores[5][1]) +
						"&mensaje=" +
						encodeURI(valores[6][1]);

					console.log(direccion);

					window.location.href = direccion;
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit}>
						<div className="row mt-5 formulario">
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="nombre">
										Nombre completo
									</label>
									<input
										className="form-control"
										type="text"
										name="nombre"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.nombre}
									/>
									<div className="error-field">
										{errors.nombre &&
											touched.nombre &&
											errors.nombre}
									</div>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="email">Email</label>
									<input
										className="form-control"
										type="email"
										name="email"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.email}
									/>
									<div className="error-field">
										{errors.email &&
											touched.email &&
											errors.email}
									</div>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="tel">Teléfono</label>
									<input
										className="form-control"
										type="tel"
										name="tel"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.tel}
									/>
									<div className="error-field">
										{errors.tel &&
											touched.tel &&
											errors.tel}
									</div>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="evento">
										Tipo de evento
									</label>
									<select
										className="form-control"
										name="evento"
										onChange={handleChange}
										onBlur={handleBlur}
									>
										<option value="0">
											- Selecciona tipo -
										</option>
										<option value="1">Boda</option>
										<option value="2">XV Años</option>
										<option value="3">Posada</option>
										<option value="4">Graduación</option>
										<option value="5">Corporativos</option>
										<option value="6">Despedida</option>
										<option value="7">Otro</option>
									</select>
									<div className="error-field">
										{errors.evento &&
											touched.evento &&
											errors.evento}
									</div>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="fecha">
										Fecha de tu evento
									</label>
									<input
										type="date"
										className="form-control"
										name="fecha"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.fecha}
									/>
									<div className="error-field">
										{errors.fecha &&
											touched.fecha &&
											errors.fecha}
									</div>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="numero">
										Número de personas aprox.
									</label>
									<select
										className="form-control"
										name="numero"
										onChange={handleChange}
										onBlur={handleBlur}
									>
										<option value="0">
											- Selecciona un número -
										</option>
										<option value="200 - 300">
											200 - 300
										</option>
										<option value="300 - 400">
											300 - 400
										</option>
										<option value="400 - 500">
											400 - 500
										</option>
										<option value="Más de 500">
											Más de 500
										</option>
									</select>
									<div className="error-field">
										{errors.numero &&
											touched.numero &&
											errors.numero}
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="mensaje">Mensaje</label>
									<textarea
										name="mensaje"
										rows="3"
										className="form-control"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.mensaje}
									></textarea>
								</div>
							</div>
							<div className="col-12">
								<button
									className="btn btn-solid extra-padding azul"
									type="submit"
									disabled={isSubmitting}
								>
									Enviar
								</button>
							</div>
						</div>
					</form>
				)}
			</Formik>
		</Fragment>
	);
};

export default Form;

import React, { Component } from "react";
import Form from "../components/Form";

class FormaContacto extends Component {
	render() {
		return (
			<div className="forma-contacto">
				<div className="inner">
					<h2>Agenda tu cita</h2>
					<p>
						Deja tus datos y uno de nuestros asesores te contactará.
					</p>
					<hr />

					<Form />
				</div>
			</div>
		);
	}
}

export default FormaContacto;

import React from "react";
// import { Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";

import gmaps from "../images/gmaps.png";
import waze from "../images/waze.png";
import download from "../images/download.svg";
import croquis from "../images/croquis.png";

import FormaContacto from "../components/FormaContacto";

const Contacto = () => (
	<Layout>
		<SEO title="Contacto" />

		<section className="banner-contacto">
			<div className="container">
				<h1 className="title">
					<small>QUEREMOS SABER DE TI</small>
					<span>CONTACTO</span>
				</h1>
			</div>
		</section>

		<section className="contacto-cont">
			<div className="container">
				<div className="row">
					<div className="col-lg-5">
						<div className="info">
							<div className="inner">
								<ul>
									<li>
										<span className="icon">
											<svg
												width="35"
												height="35"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g
													fillRule="nonzero"
													fill="none"
												>
													<path
														d="M17.5 1C8.387 1 1 8.387 1 17.5S8.387 34 17.5 34 34 26.613 34 17.5 26.613 1 17.5 1z"
														stroke="currentcolor"
													/>
													<path
														d="M19.229 18.229c-1.583 1.582-3.417 3.096-4.142 2.371-1.037-1.037-1.677-1.941-3.965-.102-2.287 1.838-.53 3.064.475 4.068 1.16 1.16 5.484.062 9.758-4.211 4.273-4.274 5.368-8.598 4.207-9.758-1.005-1.006-2.225-2.762-4.063-.475-1.839 2.287-.936 2.927.103 3.965.722.725-.791 2.559-2.373 4.142z"
														fill="currentcolor"
													/>
												</g>
											</svg>
										</span>
										<p>
											<a href="tel:8119669696">
												<strong>T.</strong> 811-966-9696
											</a>
										</p>
										<p>
											<a href="tel:811-086-0167">
												<strong>T.</strong> 811-086-0167
											</a>
										</p>
									</li>
									<li>
										<span className="icon">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="35"
												height="35"
												fill="none"
												viewBox="0 0 35 35"
											>
												<path
													fill="#B16533"
													d="M23.601 11.326A7.854 7.854 0 0 0 17.994 9c-4.367 0-7.926 3.558-7.93 7.926 0 1.399.366 2.76 1.057 3.965L10 25l4.204-1.102a7.931 7.931 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93a7.899 7.899 0 0 0-2.328-5.607h.001Zm-5.607 12.195a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.561 6.561 0 0 1 4.66 1.931 6.558 6.558 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592Zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232Z"
												/>
												<circle
													cx="17.5"
													cy="17.5"
													r="17"
													stroke="#B16533"
												/>
											</svg>
										</span>
										<p>
											<a
												href="https://wa.me/528119394600"
												target="_blank"
												rel="noopener noreferrer"
											>
												<strong>T.</strong> 811-939-4600
											</a>
										</p>
										<p>
											<a
												href="https://wa.me/528116785358"
												target="_blank"
												rel="noopener noreferrer"
											>
												<strong>T.</strong> 811-678-5358
											</a>
										</p>
									</li>
									<li>
										<span className="icon">
											<svg
												width="37"
												height="37"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g
													fillRule="nonzero"
													fill="none"
												>
													<path
														d="M18.5 1C8.835 1 1 8.835 1 18.5S8.835 36 18.5 36 36 28.165 36 18.5 28.165 1 18.5 1z"
														stroke="#B16533"
													/>
													<path
														d="M26.572 11.674l-18.539 6.61c-.298.107-.364.367-.01.51l3.986 1.615 2.362.958 11.533-8.57c.156-.115.334.1.222.224l-8.265 9.045v.002l-.474.535.629.342 5.23 2.85a.534.534 0 00.79-.356l3.052-13.307c.083-.365-.156-.587-.516-.458zM14.35 27.11c0 .261.146.334.348.15.263-.244 2.992-2.722 2.992-2.722l-3.34-1.746v4.318z"
														fill="#B16533"
													/>
												</g>
											</svg>
										</span>
										<p>
											<a href="mailto:info@barcelonaeventos.com.mx">
												<strong>E.</strong>{" "}
												info@barcelonaeventos.com.mx
											</a>
										</p>
									</li>
									<li>
										<span className="icon">
											<svg
												width="35"
												height="35"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g
													fill="none"
													fillRule="evenodd"
												>
													<path
														d="M17.5 1C8.387 1 1 8.387 1 17.5S8.387 34 17.5 34 34 26.613 34 17.5 26.613 1 17.5 1z"
														stroke="currentcolor"
													/>
													<path
														d="M18 9c3.825.096 6.904 3.154 7 6.955a7.667 7.667 0 0 1-.93 3.21l-.054.093a55.416 55.416 0 0 1-2.127 3.16C19.746 25.413 18.84 26 18 26c-.537 0-1.8 0-6.032-6.738l-.054-.097a7.666 7.666 0 0 1-.914-3.21c.096-3.801 3.175-6.86 7-6.955zm0 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"
														fill="currentcolor"
													/>
												</g>
											</svg>
										</span>
										<p>
											Boulevard Julián Treviño Elizondo
											#800, el Milagro, 66634 Apodaca,
											Nuevo León.
										</p>

										<a
											href="https://www.google.com/maps/place/Barcelona+Eventos+Apodaca/@25.7446913,-100.1862066,17z/data=!3m1!4b1!4m5!3m4!1s0x8662ebb9316b256f:0x7755f87688902eb6!8m2!3d25.7446913!4d-100.1840126"
											target="_blank"
											rel="noopener noreferrer"
											className="btn pl-0 mb-2"
										>
											<img
												className="mr-2"
												src={gmaps}
												alt="Google Maps"
												width="27"
												height="27"
											/>
											Abrir en google maps
										</a>
										<br />

										<a
											href="https://www.waze.com/ul?ll=25.74469130%2C-100.18401260&navigate=yes&zoom=16"
											target="_blank"
											rel="noopener noreferrer"
											className="btn pl-0 mb-2"
										>
											<img
												className="mr-2"
												src={waze}
												alt="Waze"
												width="28"
												height="26"
											/>
											Abrir en waze
										</a>
										<br />

										<a
											href={croquis}
											target="_blank"
											rel="noopener noreferrer"
											className="btn pl-0"
										>
											<img
												className="mr-2"
												src={download}
												alt="Download"
												width="23"
												height="21"
											/>
											Descargar croquis
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-6 offset-lg-1">
						<FormaContacto></FormaContacto>
					</div>
				</div>
			</div>
		</section>

		<div className="embed-responsive embed-responsive-16by9">
			<iframe
				title="Mapa"
				className="embed-responsive-item"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.7861508292394!2d-100.18618458487542!3d25.74458461559173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662ebc507bea467%3A0xab9aca8c2c0c5218!2sBarcelona+Eventos+Apodaca!5e0!3m2!1ses-419!2smx!4v1553030039955"
				width="600"
				height="450"
				frameBorder="0"
				allowFullScreen
			></iframe>
		</div>
	</Layout>
);

export default Contacto;
